import { Box, Link, Typography } from '@mui/material';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useCallback, useEffect, useMemo } from 'react';
import {
  useLoginMutation,
  useResetPasswordMutation,
} from '../../../plugins/gatsby-plugin-redux/store/api/auth.api';
import Container from '../../components/app/Container';
import ErrorAlert from '../../components/app/ErrorAlert';
import HtmlForm from '../../components/app/HtmlForm';
import LoadingButton from '../../components/app/LoadingButton';
import SEO from '../../components/app/SEO';
import Form from '../../field/Form';
import InputField from '../../field/InputField';
import handleSubmitAction from '../../helpers/handleSubmitAction';
import { isPassword, isRequired } from '../../helpers/validators';
import useAuthRedirect from '../../hooks/useAuthRedirect';
import { SubmitHandler } from '../../types/app';
import { ResetPasswordFormData } from '../../types/form';

const ResetPassword = () => {
  useAuthRedirect();

  const location = useLocation();
  const [resetPasswordAction] = useResetPasswordMutation();
  const [loginAction] = useLoginMutation();

  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const handleSubmit = useCallback<SubmitHandler<ResetPasswordFormData>>(async (
    values,
    helpers,
  ) => {
    await resetPasswordAction({
      ...values,
      token: decodeURIComponent(search.get('token') || ''),
      identifier: decodeURIComponent(search.get('email') || ''),
    }).unwrap();
    await loginAction({
      identifier: values.identifier,
      password: values.password,
    }).unwrap();
    await navigate('/');
    helpers.resetForm();
  }, [loginAction, resetPasswordAction, search]);

  useEffect(() => {
    if (!search.get('token') || !search.get('email')) {
      void navigate('/404');
    }
  }, [search]);

  return (
    <Container sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <SEO title="Forgot Password" />
      <Container maxWidth="sm">
        <Typography variant="h3" component="h1" fontWeight="600" textAlign="center" marginBottom={3}>Reset Password</Typography>

        <Form<ResetPasswordFormData>
          initialValues={{
            token: decodeURIComponent(search.get('token') || ''),
            identifier: decodeURIComponent(search.get('email') || ''),
            password: '',
            password_confirmation: '',
          }}
          onSubmit={handleSubmitAction(handleSubmit)}
        >
          {(config) => (
            <HtmlForm onSubmit={config.handleSubmit}>
              <ErrorAlert message={config.errors._server?.message} />
              <ErrorAlert message={config.errors.token?.message} />
              <InputField
                name="password"
                label="New Password"
                fullWidth
                type="password"
                rules={[isRequired, isPassword]}
              />
              <InputField
                name="password_confirmation"
                label="Confirm Password"
                fullWidth
                type="password"
                rules={[isRequired, isPassword]}
              />
              <LoadingButton loading={config.isLoading} type="submit" fullWidth>Reset Password</LoadingButton>

              <Box marginTop={1}>
                <Link href="/auth/forgot-password/">Back to forgot password</Link>
              </Box>
            </HtmlForm>
          )}
        </Form>
      </Container>
    </Container>
  );
};

export default ResetPassword;
